import { FormBuilder, FormControl } from '@angular/forms';
import { PaginationService } from './../../services/pagination.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SortingFilterService } from '../../services/sorting-filter.service';
import { SortFilterChangeEvent } from '../../services/SortFilterChangeEvent';

@Component({
  selector: 'app-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss']
})
export class SortFilterComponent implements OnDestroy {
  @Output() viewChanged = new EventEmitter<string>();
  @Input() documentView: boolean = false;


  form = this.fb.group({
    sort: new FormControl<string>(this.sfs!.srCreated),
    name: new FormControl<string | null>(null),
    status: new FormControl<string[] | null>(null),
    pageSize: new FormControl<string | null>(this.pgs!.pageSize?.toString()!),
    view: new FormControl<string>(this.sfs!.vmPageView),
  });

  valueChanges_: Subscription | null = null;

  constructor(
    private fb: FormBuilder,
    public pgs:PaginationService,
    public sfs:SortingFilterService
  ) {
    this.form.controls.name.valueChanges.subscribe((name) =>
      this.subscribeChangeEvent({
        ...this.form.value,
        name,
      } as SortFilterChangeEvent)
    );
    this.form.controls.sort.valueChanges.subscribe((sort) =>
      this.subscribeChangeEvent({
        ...this.form.value,
        sort,
      } as SortFilterChangeEvent)
    );
    this.form.controls.status.valueChanges.subscribe((status) =>
      this.subscribeChangeEvent({
        ...this.form.value,
        status,
      } as SortFilterChangeEvent)
    );
    this.form.controls.view.valueChanges.subscribe((value) =>
      this.viewChanged.emit(value!)
    );
    this.form.controls.pageSize.valueChanges.subscribe(
      (value) => (this.pgs!.pageSize = value)
    );
  }

  ngOnDestroy(): void {
    this.valueChanges_?.unsubscribe();
  }

  subscribeChangeEvent(change: SortFilterChangeEvent) {
    let eventChange: SortFilterChangeEvent = {
      name: change.name,
      sort: change.sort,
      status: change.status,
      pageSize: change.pageSize,
    };
    this.sfs.changed(eventChange);
  }
}
