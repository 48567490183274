import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: '[app-form-field]',
  template: `
    <div
      mwlDraggable
      (click)="select($event)"
      (dragEnd)="dragEnd($event)"
      dragActiveClass="drag-active"
      [touchStartLongPress]="{ delay: 300, delta: 30 }"
      class="field-layout"
      [ngClass]="{'active':field?.active}"
      [style.position]="'relative'"
      [style.top]="field?.top + 'px'"
      [style.left]="field?.left + 'px'"
      [style.height]="field?.height + 'px'"
      [style.width]="field?.width + 'px'"
    >

    </div>
  `,
  styles: [],
})
export class FormFieldComponent {
  @Input() field: IFormField | null = null;
  @Output() selectedField = new EventEmitter<IFormField>()
  isResize = false;

  select(event: any){
    this.selectedField.emit(this.field!)
  }
  dragEnd(event: any) {
    // do something
    this.field!.top = this.field!.top + event.y;
    this.field!.left = this.field!.left + event.x;
    this.selectedField.emit(this.field!)
  }
  dragY(event: any) {
    // do something
    console.log(event)
  }
  draggingT(event: any) {
    this.field!.height = this.field!.height - event.y;
  }
  draggingB(event: any) {
    this.field!.height = this.field!.height + event.y;
  }
  resizeStart(event: any) {
    //this.isResize = true;
    //event.stopPropagation()
  }
  move(event: any) {
    //console.log(event)
    //event.stopPropagation()
  }
  resizeEnd(event: any) {
    //this.isResize = false;
    //event.stopPropagation()
  }
  isDrag() {
    return !this.isResize;
  }
}
export interface IFormField {
  top: number;
  left: number;
  height: number;
  width: number;
  name: string;
  type: string;
  active: boolean;
}
