import { Injectable, signal } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap, map, from, filter, switchMap, catchError, Observable } from 'rxjs';
import { DocumentGroupsService, DocumentGroup, GetDocumentGroupResponse, GetDocumentGroupsResponse } from '../../api/form-extract';
import { AdminDocumentGroupModalComponent } from 'src/app/routes/admin/modals/admin-document-group-modal/admin-document-group-modal.component';
import { ProjectHandlerService } from './projectHandler.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { RouteListenerService } from '../route-listener.service';
import { GetDocumentGroupRequest } from '../../api/form-extract/model/getDocumentGroupRequest';

@Injectable({
  providedIn: 'root',
})
export class DocumentGroupHandlerService {

  projectId!: string;
  documentGroupId!: string;

 constructor(
    private documentGroupService: DocumentGroupsService,
    private modalService: NgbModal,
    private projectHandler: ProjectHandlerService,
    private routeListener: RouteListenerService
  ) {

  }

  documentGroup$ = signal<DocumentGroup | null>(null);
  _documentGroupId = toObservable(this.routeListener.documentGroupId$)
  .pipe(
    filter((prm)=>prm != ''),
    tap((documentGroupId) => {
      this.documentGroupId = documentGroupId;
    }),
    switchMap((documentGroupId)=>this.documentGroupService.getDocumentGroup(
      documentGroupId,
      this.routeListener.projectId$()
      )),
      tap((documentGroup)=>{
        this.documentGroup$.set(documentGroup.item!)
      })
  )
  .subscribe();
  documentGroupList$ = signal<Array<DocumentGroup>>([]);


  loadDocumentGroupsRequest() {
    const projectId = this.projectHandler.projectId!;
    let DocumentGroups$ =
      this.documentGroupService.getDocumentGroups(projectId!,'10','1')
    DocumentGroups$.pipe(
      map((response) => response),
      tap((response) => this.documentGroupList$.set(response.pagedItems!))
    ).subscribe();
  }
  getDocumentGroupsRequest(pageSize: number, pageNumber: number) : Observable<GetDocumentGroupsResponse> {
    const projectId = this.projectHandler.projectId!;
    return    this.documentGroupService.getDocumentGroups(projectId!,undefined,undefined,pageSize.toString(),pageNumber.toString())
    .pipe(
      map((response) => response),
      tap((response) =>
      this.documentGroupList$.set(response.pagedItems!)),
      map((response) => response)
    )
  }

  startDocumentGroupsRequest() {
    let documentGroups$ =
      this.documentGroupService.getDocumentGroups('10', '1');
    documentGroups$
      .pipe(
        map((response) => response),
        tap((response) => this.documentGroupList$.set(response.pagedItems!))
      )
      .subscribe();
  }

  createDocumentGroupRequest() {
    const projectId = this.projectHandler.projectId!;
    const modalRef = this.modalService.open(AdminDocumentGroupModalComponent,{
      fullscreen: true
    });
    modalRef.componentInstance.operation = 'Create';
    from(modalRef.result)
      .pipe(
        filter((val) => val.result),
        switchMap((val) =>
          this.documentGroupService
            .createDocumentGroup(projectId, {
              name: val.value.name,
              description: val.value.description,
              instructions: val.value.instructions,
              preProcessingScript: val.value.preProcessingScript,
              posProcessingScript: val.value.posProcessingScript,
              isSingleEntityDocument: val.value.isSingleEntityDocument,
              isMultipleEntityDocument: val.value.isMultipleEntityDocument,
              isPdfExtractSupported: val.value.isPdfExtractSupported,
              isGenerativeAiEnabled: val.value.isGenerativeAiEnabled,
              projectId: projectId
            })
            .pipe(
              tap((response) => {
                this.documentGroupList$.mutate((current) =>
                  current.push(response.item!)
                );
              })
            )
        ),
        catchError((error) => error)
      )
      .subscribe();
  }
  editDocumentGroupRequest(documentGroup: DocumentGroup) {
    const projectId = this.projectHandler.projectId!;
    const modalRef = this.modalService.open(
      AdminDocumentGroupModalComponent,
      {
        fullscreen:true
      }
    );
    modalRef.componentInstance.operation = 'Edit';
    modalRef.componentInstance.documentGroupInfo = documentGroup;
    from(modalRef.result)
      .pipe(
        filter((val) => val.result),
        switchMap((val) =>
          this.documentGroupService
            .patchDocumentGroup(
              projectId,
              documentGroup.id!,
              {...val.value,...{id:documentGroup.id, projectId: projectId}}
            )
            .pipe(
              tap((response) => {
                this.documentGroupList$.mutate((current) => {
                  const ref = current.find((c) => c.id == documentGroup.id!)! as any;
                  Object.keys(response.item!).forEach((key)=>{
                    const updatedDocGroup = response.item! as any;
                    ref[key] = updatedDocGroup[key]
                  })

                });
              })
            )
        ),
        catchError((error) => error)
      )
      .subscribe();
  }
  removeDocumentGroupRequest(documentGroup: DocumentGroup) {
    const projectId = this.projectHandler.projectId!;
    const modalRef = this.modalService.open(AdminDocumentGroupModalComponent);
    modalRef.componentInstance.operation = 'Delete';
    modalRef.componentInstance.documentGroupInfo = documentGroup;

    from(modalRef.result)
      .pipe(
        filter((val) => val.result),
        switchMap(() =>
          this.documentGroupService
            .removeDocumentGroup(
              projectId,
              documentGroup.id!,
              {
                id: documentGroup.id!,
                projectId: projectId
              }
            )
            .pipe(
              tap(() => {
                this.documentGroupList$.mutate((current) => {
                  const DocumentGroupIndex = current.findIndex(
                    (c) => c.id == documentGroup.id!
                  )!;
                  current.splice(DocumentGroupIndex, 1);
                });
              })
            )
        ),
        catchError((error) => error)
      )
      .subscribe();
  }
}
