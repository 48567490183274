import { PaginationService } from './../../../../shared/services/pagination.service';
import { AfterViewInit, Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscribable, Subscription, tap } from 'rxjs';
import { Document, DocumentGroup } from 'src/app/shared/api/form-extract';
import { SortFilterChangeEvent } from 'src/app/shared/services/SortFilterChangeEvent';
import { DocumentGroupHandlerService } from 'src/app/shared/services/mediator/documentGroupHandler.service';
import { DocumentHandlerService } from 'src/app/shared/services/mediator/documentHandler.service';
import { SortingFilterService } from 'src/app/shared/services/sorting-filter.service';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
@Component({
  selector: 'app-page-document-list',
  templateUrl: './page-document-list.component.html',
  styleUrls: ['./page-document-list.component.scss'],
  providers: [PaginationService,SortingFilterService],
})
export class PageDocumentListComponent implements OnInit, OnDestroy, AfterViewInit{
  _documentList$ = signal<Document[]>([]);

  public get blobPath(): string {
    return environment.blobStorage;
  }
  tableViewEnabled = false
  paginationSubscription: Subscription | null = null;
  filterSubscription: Subscription | null = null;
  constructor(
    public documentHandler: DocumentHandlerService,
    public groupHandler: DocumentGroupHandlerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public pgs: PaginationService,
    public sfs: SortingFilterService
  ) {

  }
  ngAfterViewInit(): void {
    this.paginationSubscription = this.pgs.valueChanges$.subscribe((pageNumber) => {
      this.list(pageNumber, this.sfs.value);
    });
    this.filterSubscription = this.sfs.valueChanges$.subscribe((sortFilterEvent)=>{
      this.list(this.pgs.pageNumber, sortFilterEvent)
    })
    this.pgs.pageNumber = 1;
  }
  ngOnDestroy(): void {
    this.paginationSubscription?.unsubscribe()
    this.filterSubscription?.unsubscribe()
    this._documentList$.set([])
  }

  ngOnInit(): void {


  }


  viewChanged(viewName: string) {
    this.tableViewEnabled = viewName == this.sfs.vmTableView
  }
  list(pageNumber: number | null, sortFilter: SortFilterChangeEvent | null) {
    this.documentHandler
      .getDocumentsByGroup(
        this.groupHandler.documentGroupId,
        this.pgs.pageSize,
        pageNumber,
        sortFilter?.name ?? null,
        sortFilter?.sort ?? null,
        sortFilter?.status ?? null
      )
      .pipe(
        tap((response) => {
          this._documentList$.set(response.pagedItems!);
          this.pgs.pageCount = response.pageCount!;
        })
      )
      .subscribe();
  }

  reprocess(item: Document) {
    this.documentHandler.reprocessDocument(item).subscribe((response) => {
      item.documentStatus = 'Extracting';
    });
  }
  deleteItem(item: Document) {
    this.documentHandler.deleteDocument(item).subscribe((response) => {
      item.documentStatus = 'Extracting';
    });
  }
  view(item: Document) {
    this.router.navigate([item.id], { relativeTo: this.activatedRoute });
  }
  download(item: Document) {
    this.router.navigate([item.id], { relativeTo: this.activatedRoute });
  }
  formStudio(item: Document) {
    this.router.navigate([item.id, 'studio'], {
      relativeTo: this.activatedRoute,
    });
  }
  export(item: Document) {
    this.documentHandler
      .downloadDocument(item)
      .pipe(
        tap((response) => {
          window.open(`${this.blobPath}/${response.location!}`);
        })
      )
      .subscribe();
  }
}
