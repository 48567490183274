<div
  class="flex-grow-1 d-flex flex-wrap justify-content-between align-items-center gap-3"
>
  <button type="button" class="btn btn-secondary btn-lg hstack gap-2 mb-1" [routerLink]="['../../../upload',groupHandler.documentGroupId]">
    <i class="demo-psi-add fs-4"></i>
    <span class="vr"></span>
    Add new
  </button>

  <div class="w-auto text-nowrap mb-1">
    <app-sort-filter (viewChanged)="viewChanged($event)" [documentView]="true"></app-sort-filter>
  </div>
</div>
<div class="card mb-4">
  <div class="card-body">
    <div class="row">
      <div
        [ngClass]="tableViewEnabled ? 'col-12': 'col-sm-6 col-md-4 col-xl-3 mb-3'"
        *ngFor="let item of _documentList$()">

      <app-document-preview [item]="item" [tableView]="tableViewEnabled">
        <ul class="dropdown-menu dropdown-menu-end menu">
          <li>
            <button (click)="export(item)" target="_blank" class="dropdown-item">
              <i class="demo-pli-mail fs-5 me-2"></i> Export
            </button>
          </li>

          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <button (click)="view(item)" class="dropdown-item">
              <i class="demo-pli-mail fs-5 me-2"></i> View Document
            </button>
          </li>
          <li>
            <button (click)="formStudio(item)"  class="dropdown-item">
              <i class="demo-pli-calendar-4 fs-5 me-2"></i> Form Studio
            </button>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <button (click)="reprocess(item)" class="dropdown-item">
              <i class="demo-pli-lock-user fs-5 me-2"></i> Reprocess
            </button>
          </li>
          <li>
            <button (click)="deleteItem(item)" class="dropdown-item">
              <i class="demo-pli-lock-user fs-5 me-2"></i> Delete
            </button>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <a [href]="blobPath +'\\'+ item.documentStoragePath+'\\log.txt'" target="_blank" class="dropdown-item">
              <i class="demo-pli-calendar-4 fs-5 me-2"></i> Logs
            </a>
          </li>
        </ul>
      </app-document-preview>

      </div>
      <app-pagination [pgs]="pgs">

      </app-pagination>
    </div>
  </div>
</div>
