<div class="flex-grow-1 d-flex flex-wrap justify-content-end align-items-center gap-3">

  <!-- <button type="button" class="btn btn-secondary btn-lg hstack gap-2 mb-1">
      <i class="demo-psi-add fs-4"></i>
      <span class="vr"></span>
      Add new
  </button>

  <div class="w-auto text-nowrap mb-1">
      <span class="d-inline-block me-2">Sort by : </span>
      <select class="d-inline-block w-auto form-select">
          <option value="date-created" selected="">Date Created</option>
          <option value="date-modified">Date Modified</option>
          <option value="frequency-used">Frequency Used</option>
          <option value="alpabetically">Alpabetically</option>
          <option value="alpabetically-reversed">Alpabetically Reversed</option>
      </select>

      <button type="button" class="btn btn-light ms-1">Filter</button>
      <button type="button" class="btn btn-light btn-icon ms-1">
          <i class="demo-pli-gear fs-5"></i>
      </button>
  </div> -->
</div>
<div class="card mb-4">
  <div class="card-body">
    <div class="row">

    </div>
  </div>
  <div class="card-body" >
    <div class="row">
      <div class="col-8">
        <label class="btn btn-default p-0">
          <input type="file" multiple (change)="selectFiles($event)" />
        </label>
      </div>

      <div class="col-4">
        <button
          class="btn btn-success btn-sm"
          [disabled]="!selectedFiles"
          (click)="uploadFiles()"
        >
          Upload
        </button>
      </div>

    </div>
    <div *ngFor="let progressInfo of progressInfos" class="mb-2">
      <span>{{ progressInfo.fileName }}</span>
      <div class="progress">
        <div
          class="progress-bar progress-bar-info"
          role="progressbar"
          attr.aria-valuenow="{{ progressInfo.value }}"
          aria-valuemin="0"
          aria-valuemax="100"
          [ngStyle]="{ width: progressInfo.value + '%' }"
        >
          {{ progressInfo.value }}%
        </div>
      </div>
    </div>

    <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
      <ul *ngFor="let msg of message; let i = index">
        <li>{{ msg }}</li>
      </ul>
    </div>

    <div class="card mt-3">
      <div class="card-header">List of Files</div>
      <ul class="list-group list-group-flush">
        <li *ngFor="let file of fileInfos | async" class="list-group-item">
          <a href="{{ file.url }}">{{ file.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>



