import { Component, WritableSignal, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteListenerService } from '../../services/route-listener.service';
import { DocumentGroupHandlerService } from '../../services/mediator/documentGroupHandler.service';
import { ProjectHandlerService } from '../../services/mediator/projectHandler.service';
import { DocumentGroup, Project } from '../../api/form-extract';
import { AuthService } from '../../services/auth.service';
import { DocumentHandlerService } from '../../services/mediator/documentHandler.service';

@Component({
  selector: 'div[app-admin-layout]',
  host: {
    // this is applied to 'my-node' in this case
    '[class]': "'root mn--max hd--expanded'",
    '[attr.id]': "'root'",
  },
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
  get showProjectMenu(): boolean {
    return this.projectHandlerService.project$() != null;
  }
  get project(): Project | null {
    return this.projectHandlerService.project$();
  }
  get documentGroup(): DocumentGroup | null {
    return this.documentGroupHandlerService.documentGroup$();
  }
  get document(): DocumentGroup | null {
    return this.documentHandlerService.document$();
  }

  projectName = computed(() => this.project?.name);
  documentGroupName = computed(() => this.documentGroup?.name);
  documentName = computed(() => this.document?.name);

  self: any = this;


  constructor(
    public routeListener: RouteListenerService,
    private projectHandlerService: ProjectHandlerService,
    private documentGroupHandlerService: DocumentGroupHandlerService,
    private documentHandlerService: DocumentHandlerService,
    public authService: AuthService
  ) {
    
  }
  logout() {
    this.authService.logout();
  }
}
