import { Component, OnInit } from '@angular/core';
import { Document } from 'src/app/shared/api/form-extract';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-viewer-modal',
  templateUrl: './document-viewer-modal.component.html',
  styleUrls: ['./document-viewer-modal.component.scss']
})
export class DocumentViewerModalComponent implements OnInit{

  page:any | null = null;
  imageBg: string | null = null;
  constructor(){

  }
  ngOnInit(): void {
    this.imageBg = `${environment.blobStorage}/${this.page?.coverUrl ?? this.page?.storagePath}`

  }

}
