import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { AdminDocumentGroupsComponent } from './pages/admin-document-groups/admin-document-groups.component';
import { AdminUploadComponent } from './pages/admin-upload/admin-upload.component';
import { PageDocumentsQueueComponent } from './pages/page-documents-queue/page-documents-queue.component';
import { PageDocumentListComponent } from './pages/page-document-list/page-document-list.component';
import { PageDocumentPagesListComponent } from './pages/page-document-pages-list/page-document-pages-list.component';
import { FormStudioComponent } from './pages/form-studio/form-studio.component';

const routes: Routes = [
  {
    path: '',
    component: AdminHomeComponent,
    data: {
      title: 'Projects',
    },
  },
  {
    path: ':projectId',
    redirectTo: ':projectId/groups',
  },
  {
    path: ':projectId/groups',
    component: AdminDocumentGroupsComponent,
    data: {
      title: 'Groups',
    },
  },
  {
    path: ':projectId/groups/:documentGroupId',
    redirectTo: ':projectId/groups/:documentGroupId/documents',
  },
  {
    path: ':projectId/groups/:documentGroupId/documents',
    component: PageDocumentListComponent,
    data: {
      title: 'Groups',
    },
  },
  {
    path: ':projectId/groups/:documentGroupId/documents/:documentId',
    redirectTo:
      ':projectId/groups/:documentGroupId/documents/:documentId/pages',
  },
  {
    path: ':projectId/groups/:documentGroupId/documents/:documentId/pages',
    component: PageDocumentPagesListComponent,
    data: {
      title: 'Pages',
    },
  },
  {
    path: ':projectId/groups/:documentGroupId/documents/:documentId/studio',
    component: FormStudioComponent,
    data: {
      title: 'Form Studio',
    },
  },
  {
    path: ':projectId/upload/:documentGroupId',
    component: AdminUploadComponent,
    data: {
      route: {
        projectLink: 'Upload',
      },
    },
  },
  {
    path: ':projectId/upload',
    component: AdminUploadComponent,
    data: {
      route: {
        projectLink: 'Upload',
      },
    },
  },
  {
    path: ':projectId/queue',
    component: PageDocumentsQueueComponent,
    data: {
      route: {
        projectLink: 'Queue',
      },
    },
  },
  {
    path: ':projectId/queue/:documentGroupId',
    component: PageDocumentsQueueComponent,
    data: {
      route: {
        projectLink: 'Queue',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
