<div
  class="flex-grow-1 d-flex flex-wrap justify-content-end align-items-center gap-3"
>
  <!-- <button type="button" class="btn btn-secondary btn-lg hstack gap-2 mb-1">
    <i class="demo-psi-add fs-4"></i>
    <span class="vr"></span>
    Add new
  </button> -->

  <div class="w-auto text-nowrap mb-1">
    <!-- <app-sort-filter></app-sort-filter> -->
  </div>
</div>
<div class="card mb-4">
  <div class="card-body">
    <div class="row" *ngIf="_pages$()">
      <div
        class="col-sm-6 col-md-4 col-xl-3 mb-3"
        *ngFor="let item of _pages$()"
      >
      <app-document-preview [item]="item">
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a [href]="blobStorage +'\\'+ item.transformedContentStoragePath" target="_blank" class="dropdown-item">
              <i class="demo-pli-mail fs-5 me-2"></i> Generative AI
            </a>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <a [href]="blobStorage +'\\'+ item.ocrStoragePath" target="_blank" class="dropdown-item">
              <i class="demo-pli-mail fs-5 me-2"></i> OCR Document
            </a>
          </li>
          <li>
            <a [href]="blobStorage +'\\'+ item.extractedStoragePath" target="_blank" class="dropdown-item">
              <i class="demo-pli-calendar-4 fs-5 me-2"></i> Reader Document
            </a>
          </li>
          <!-- <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <button class="dropdown-item">
              <i class="demo-pli-lock-user fs-5 me-2"></i> Reprocess
            </button>
          </li> -->
        </ul>
      </app-document-preview>

      </div>
    </div>
  </div>
</div>
