import { Component, Signal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { GetProjectsResponse, Project } from 'src/app/shared/api/form-extract';
import { MediatorService } from 'src/app/shared/services/mediator/mediator.service';
import { ProjectHandlerService } from 'src/app/shared/services/mediator/projectHandler.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent {


  get projectList(): Signal<Array<Project>>{
    return this.projectHandler.projectList$
  }

  constructor(private projectHandler: ProjectHandlerService, private router: Router){

  }
  ngOnInit(): void {
    this.projectHandler.loadProjectsRequest();
  }

  start(){

  }
  groups(project:Project){
    this.projectHandler.project$.set(project);
    this.router.navigate(['admin',project.id,'groups'])
  }
  createProject(){
    this.projectHandler.createProjectRequest()
  }
  editProject(project: Project){
    this.projectHandler.editProjectRequest(project)
  }
  removeProject(project: Project){
    this.projectHandler.removeProjectRequest(project)
  }
  uploadDocuments(project: Project){
    this.projectHandler.uploadDocumentsRequest(project);
  }
}
