<div class="form-studio">
  <div [ngStyle]="{
    'width':'100%',
    'height':'100%'
  }">
    <div
      mwlDraggable
      class="page-container"
      (mousewheel)="zoomChange($event)"
      [ngStyle]="{
        'background-image':'url('+layout.pageCoverUrl+')',
        'min-width':layout.width+'px',
        'min-height':layout.height+'px',
        'zoom':zoom
      }"
    >
      <div class="fields-overlay">
        <div app-form-field
          [field]="field"
          *ngFor="let field of layout.fields"
          (selectedField)="updateField($event)"
        ></div>
      </div>
    </div>
</div>
  <div class="page-toolbar">
    <div  class="form-inline m-2 row" *ngIf="selectedField">
      <div class="form-group col-12">
        <label>Name:</label>
        <input [(ngModel)]="selectedField!.name" (change)="updateBackField($event)" class="form-control" type="text" />
      </div>
      <div class="form-group col-12">
        <label>Type:</label>
        <select [(ngModel)]="selectedField!.type" class="form-control">
          <option value="text">Text</option>
          <option value="date">Date</option>
          <option value="number">Number</option>
          <option value="boolean">Yes/No</option>
        </select>
      </div>
      <div class="form-group col-6">
        <label>Top:</label>
        <input [(ngModel)]="selectedField!.top" class="form-control" type="number" />
      </div>
      <div class="form-group col-6">
        <label>Left:</label>
        <input [(ngModel)]="selectedField!.left" class="form-control" type="number" />
      </div>
      <div class="form-group col-6">
        <label>Width:</label>
        <input [(ngModel)]="selectedField!.width"  class="form-control" type="number" />
      </div>
      <div class="form-group col-6">
        <label>Height:</label>
        <input [(ngModel)]="selectedField!.height"  class="form-control" type="number" />
      </div>
    </div>
  </div>
  <div class="page-preview">
    <pre>
      <p>{{output$() | json}}</p>
    </pre>

  </div>
</div>
