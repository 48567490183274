import { Injectable, signal } from '@angular/core';
import { tap, map, Observable, filter, switchMap } from 'rxjs';
import {
  DocumentsService,
  Document,
  GetDocumentsResponse,
  UpdateDocumentResponse,
  ReprocessDocumentResponse,
  GetDocumentResponse,
  PagesService,
  RemoveDocumentResponse,
  ExportDocumentRequest,
  ExportDocumentResponse,
} from '../../api/form-extract';
import { ProjectHandlerService } from './projectHandler.service';
import { RouteListenerService } from '../route-listener.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { DocumentGroupHandlerService } from './documentGroupHandler.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentHandlerService {
  projectId!: string;

  document$ = signal<Document | null>(null);
  _documentId = toObservable(this.routeListener.documentId$)
    .pipe(
      filter((prm) => prm != ''),
      tap((documentId) => {
        this.documentId = documentId;
      }),
      switchMap((documentId) => this.getDocumentById(documentId)),
      tap((document) => {
        this.document$.set(document.item!);
      })
    )
    .subscribe();

  documentId: string | null = null;

  constructor(
    private documentService: DocumentsService,
    private projectHandler: ProjectHandlerService,
    private pageService: PagesService,
    private documentGroupHandler: DocumentGroupHandlerService,
    private routeListener: RouteListenerService
  ) {}

  documentList$ = signal<Array<Document>>([]);
  pageSize = '10';
  pageNumber = '1';
  documentStatus: string | null = null;
  documentGroupId = null;

  loadDocumentsRequest(
    documentGroupId?: string | undefined,
    documentStatus?: string[] | undefined,
    pageSize?: string | undefined,
    pageNumber?: string | undefined
  ): Observable<GetDocumentsResponse> {
    const projectId = this.projectHandler.projectId!;
    let documentLoaded$ = this.documentService.getDocuments(
      projectId,
      documentGroupId,
      documentStatus,
      undefined,undefined,
      pageSize,
      pageNumber
    );
    return documentLoaded$;
  }
  getDocumentById(documentId: string): Observable<GetDocumentResponse> {
    const projectId = this.projectHandler.projectId!;
    const documentGroupId = this.documentGroupHandler.documentGroupId!;
    const obs = this.documentService.getDocument(documentId, projectId);
    return obs;
  }
  getDocumentsByGroup(
    documentGroupId: string,
    pageSize: number | null,
    pageNumber: number | null,
    name: string | null,
    sort: string | null,
    status: string[] |null
  ): Observable<GetDocumentsResponse> {
    const projectId = this.projectHandler.projectId!;
    let documentLoaded$ = this.documentService.getDocuments(
      projectId,
      documentGroupId,
      status ?? undefined,
      undefined,
      name ?? undefined,
      sort ?? undefined,
      pageSize?.toString(),
      pageNumber?.toString(),
    );
    return documentLoaded$;
  }
  getDocumentPages(documentId: string) {
    const projectId = this.projectHandler.projectId!;
    let pagesLoaded$ = this.pageService.getPages(projectId, documentId);
    return pagesLoaded$;
  }
  sortDocument(
    documentId: string,
    documentGroupId: string
  ): Observable<UpdateDocumentResponse> {
    const projectId = this.projectHandler.projectId!;

    let documentPatch$ = this.documentService.patchDocument(
      projectId,
      documentId,
      {
        id: documentId,
        documentGroupId: documentGroupId,
      }
    );

    return documentPatch$;
  }
  reprocessDocument(item: Document): Observable<ReprocessDocumentResponse> {
    const projectId = this.projectHandler.projectId!;
    return this.documentService.reprocessDocuments(projectId, {
      documentId: item.id!,
      projectId: projectId,
    });
  }
  deleteDocument(item: Document): Observable<RemoveDocumentResponse> {
    const projectId = this.projectHandler.projectId!;
    return this.documentService.removeDocument(projectId, item.id!);
  }
  downloadDocument(item: Document): Observable<ExportDocumentResponse> {
    const projectId = this.projectHandler.projectId!;
    return this.documentService.exportDocument(projectId, item.id!, {
      id: item.id,
      excludedPaths: null,
    });
  }
}
