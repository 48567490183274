import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'src/app/shared/api/form-extract';

@Component({
  selector: 'app-admin-project-modal',
  standalone: true,
  templateUrl: './admin-project-modal.component.html',
  styleUrls: ['./admin-project-modal.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class AdminProjectModalComponent {
  operation = 'Create';
  createForm = this.fb.group({
    name: new FormControl('', [Validators.required]),
    description: '',
  });
  editForm = this.fb.group({
    name: new FormControl('', [Validators.required]),
    description: '',
  });
  public projectInfo: Project | null =  null;
  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) {

  }
  ngOnInit(): void {
    if(this.projectInfo != null)
      this.editForm.setValue({
        name: this.projectInfo.name!,
        description: this.projectInfo.description!
      })

  }
  onCreateSubmit() {
    // TODO: Use EventEmitter with form value
    if(this.createForm.valid)
      this.activeModal.close({
        result: true,
        value: this.createForm.value,
      });
  }
  onEditSubmit() {
    // TODO: Use EventEmitter with form value
    if(this.editForm.valid)
      this.activeModal.close({
        result: true,
        value: this.editForm.value,
      });
  }
  onRemoveSubmit() {
    // TODO: Use EventEmitter with form value

      this.activeModal.close({
        result: true,
        value: this.createForm.value,
      });
  }
}
