<div class="modal-header">
  <h4 class="modal-title">Create Group</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div class="tab-base tab-vertical">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs dg-modal-tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabsHome"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Home
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabsProfile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Transform
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabsLLM"
          type="button"
          role="tab"
          aria-controls="llm"
          aria-selected="false"
        >
          LLM
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabsContact"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          PDF Reader
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabSorting"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          Sorting
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabSorting"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          Scripting
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabSorting"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          WebHooks
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#_dm-verTabSorting"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          Automation
        </button>
      </li>
    </ul>

    <!-- Tabs content -->
    <div class="tab-content dg-modal-tab-content">
      <div
        id="_dm-verTabsHome"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <h5 class="card-title">Home tab</h5>
        <form
          class="row g-3 needs-validation"
          novalidate=""
          [formGroup]="editForm"
        >
          <div class="col-md-4">
            <label for="_dm-vCustomFirsname" class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name" />
            <div class="invalid-feedback">Please input your first name</div>
          </div>
          <div class="col-md">
            <label for="_dm-vCustomLastname" class="form-label"
              >Description</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="description"
            />
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please input your last name</div>
          </div>
          <div class="col-md-12">
            <label for="_dm-vCustomLastname" class="form-label"
              >Instruction</label
            >
            <textarea
              type="text"
              class="form-control"
              formControlName="instructions"
              rows="10"
            ></textarea>
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please input your last name</div>
          </div>
          <div class="col-md">
            <label for="_dm-vCustomLastname" class="form-label"
              >Pre Processing Script</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="preProcessingScript"
            />
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please input your last name</div>
          </div>
          <div class="col-md">
            <label for="_dm-vCustomLastname" class="form-label"
              >Post Processing Script</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="posProcessingScript"
            />
            <div class="valid-feedback">Looks good!</div>
            <div class="invalid-feedback">Please input your last name</div>
          </div>
        </form>

        <!-- <div class="col text-center" *ngSwitchCase="'Delete'">
              <p>Confirm delete Project {{documentGroupInfo?.name}}</p>
            </div> -->
      </div>
      <div
        id="_dm-verTabsProfile"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <h5 class="card-title">Transform</h5>

        <!-- Checkboxes -->
        <form
          class="row g-3 needs-validation"
          novalidate=""
          [formGroup]="editForm"
        >
          <label class="col-sm-3 col-form-label">Options</label>
          <div class="col-sm-9 py-2">
            <div class="form-check mb-2">
              <input
                id="isSingleBlock"
                formControlName="isSingleEntityDocument"
                class="form-check-input"
                type="checkbox"
                checked=""
              />
              <label for="isSingleBlock" class="form-check-label">
                Document as a single block
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="isMultipleEntities"
                formControlName="isMultipleEntityDocument"
                class="form-check-input"
                type="checkbox"
              />
              <label for="isMultipleEntities" class="form-check-label">
                Block has multiples entities
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="isPdfExtractSupported"
                formControlName="isPdfExtractSupported"
                class="form-check-input"
                type="checkbox"
              />
              <label for="isPdfExtractSupported" class="form-check-label">
                Enable PDF Reader
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="isGenerativeEnabled"
                formControlName="isGenerativeAiEnabled"
                class="form-check-input"
                type="checkbox"
              />
              <label for="isGenerativeEnabled" class="form-check-label">
                Enable Generative AI
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="isVisionApiEnabled"
                formControlName="isVisionApiEnabled"
                class="form-check-input"
                type="checkbox"
              />
              <label for="isVisionApiEnabled" class="form-check-label">
                Enable Vision API
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                id="isLayoutModeEnabled"
                formControlName="isLayoutModeEnabled"
                class="form-check-input"
                type="checkbox"
              />
              <label for="isLayoutModeEnabled" class="form-check-label">
                Layout Mode
              </label>
            </div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Language</label
          >
          <div class="col-sm-9">
            <select
              id="ocrLanguage"
              formControlName="ocrLanguage"
              class="form-control"
              placeholder=""
              aria-describedby="inputHelp"
            >
              <option value="pt-BR">pt-BR</option>
              <option value="en-US">en-US</option>
            </select>
            <div id="ocrLanguage" class="form-text">Language.</div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >OCR</label
          >
          <div class="col-sm-9">
            <input
              id="ocrCustomInput"
              formControlName="ocrCustomInput"
              class="form-control"
              placeholder=""
              aria-describedby="inputHelp"
              type="text"
            />
            <div id="ocrCustomInput" class="form-text">OCR.</div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >OCR PageMode</label
          >
          <div class="col-sm-9">
            <select
              id="ocrPageMode"
              formControlName="ocrPageMode"
              class="form-control"
            >
              <option value="1">AutoOsd</option>
              <option value="2">AutoOnly</option>
              <option value="3">Auto</option>
              <option value="4">SingleColumn</option>
              <option value="5">SingleBlockVertText</option>
              <option value="6">SingleBlock</option>
              <option value="7">SingleLine</option>
              <option value="8">SingleWord</option>
              <option value="9">CircleWord</option>
            </select>
            <div id="ocrPageMode" class="form-text">PageMode.</div>
          </div>

          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Entity/Separator</label
          >
          <div class="col-sm-9">
            <input
              id="entitySeparator"
              type="text"
              formControlName="entitySeparator"
              class="form-control"
              placeholder="Regex"
              aria-describedby="inputHelp"
            />
            <div id="entitySeparator" class="form-text">
              Regex expression split entities in the block.
            </div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Entity/Start</label
          >
          <div class="col-sm-9">
            <input
              id="entitySeparator"
              type="text"
              formControlName="entityStart"
              class="form-control"
              placeholder="Regex"
              aria-describedby="inputHelp"
            />
            <div id="entitySeparator" class="form-text">
              Regex expression split entities in the block.
            </div>
          </div>
        </form>
      </div>
      <div
        id="_dm-verTabsLLM"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="llm"
      >
        <h5 class="card-title">LLM</h5>

        <!-- Checkboxes -->
        <form
          class="row g-3 needs-validation"
          novalidate=""
          [formGroup]="editForm"
        >
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Temperature</label
          >
          <div class="col-sm-9">
            <input
              id="llmTemperature"
              type="text"
              formControlName="llmTemperature"
              class="form-control"
              placeholder="0.3"
              aria-describedby="inputHelp"
            />
            <div id="llmTemperature" class="form-text">Temperature.</div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Assistant:</label
          >
          <div class="col-sm-9">
            <textarea
              id="llmAssistantPrompt"
              type="text"
              formControlName="llmSystemPrompt"
              class="form-control"
              placeholder=""
              aria-describedby="inputHelp"
            ></textarea>
            <div id="llmAssistantPrompt" class="form-text">
              Assistant prompt.
            </div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Vision Assistant:</label
          >
          <div class="col-sm-9">
            <textarea
              id="visionApiPrompt"
              type="text"
              formControlName="visionApiPrompt"
              class="form-control"
              placeholder=""
              aria-describedby="inputHelp"
            ></textarea>
            <div id="visionApiPrompt" class="form-text">
              Vision prompt.
            </div>
          </div>
        </form>
      </div>
      <div
        id="_dm-verTabsContact"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <h5 class="card-title">PDF Reader</h5>
        <form id="pdfToTextOptionsForm" [formGroup]="editForm">
          <!-- Numeric properties -->
          <div class="row">
            <label _ngcontent-ng-c1872417165="" class="col-sm-3 col-form-label"
              >Range of pages</label
            >

            <div class="col-9">
              <div class="form-group">
                <label for="firstPage">First Page</label>
                <input
                  type="text"
                  formControlName="pdfReaderStartPageNumber"
                  class="form-control"
                  id="firstPage"
                />
              </div>

              <div class="form-group">
                <label for="lastPage">Last Page</label>
                <input
                  type="text"
                  formControlName="pdfReaderEndPageNumber"
                  class="form-control"
                  id="lastPage"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <label _ngcontent-ng-c1872417165="" class="col-sm-3 col-form-label"
              >Text Layout</label
            >
            <div class="col-9">
              <!-- Checkbox properties for booleans -->
              <div class="form-check">
                <input
                  type="radio"
                  formControlName="pdfReaderTextLayout"
                  class="form-check-input"
                  value="layout"
                />
                <label class="form-check-label" for="layout">Layout</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  formControlName="pdfReaderTextLayout"
                  class="form-check-input"
                  value="simple"
                />
                <label class="form-check-label" for="simple">Simple</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  formControlName="pdfReaderTextLayout"
                  class="form-check-input"
                  value="simple2"
                />
                <label class="form-check-label" for="simple2">Simple2</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  formControlName="pdfReaderTextLayout"
                  class="form-check-input"

                  value="table"
                />
                <label class="form-check-label" for="table">Table</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  formControlName="pdfReaderTextLayout"
                  class="form-check-input"

                  value="lineprinter"
                />
                <label class="form-check-label" for="lineprinter">Lineprinter</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  formControlName="pdfReaderTextLayout"
                  class="form-check-input"

                  value="raw"
                />
                <label class="form-check-label" for="raw">Raw</label>
              </div>
            </div>
          </div>





        </form>
      </div>
      <div
        id="_dm-verTabSorting"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="sorting-tab"
      >
        <h5 class="card-title">Sorting</h5>
        <form
          class="row g-3 needs-validation"
          novalidate=""
          [formGroup]="editForm"
        >
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Keywords</label
          >
          <div class="col-sm-9">
            <input
              id="entitySeparator"
              type="text"
              formControlName="sortingKeywords"
              class="form-control"
              placeholder=""
              aria-describedby="inputHelp"
            />
            <div id="entitySeparator" class="form-text">Document keywords.</div>
          </div>
          <label for="_dm-textInputWitHelp" class="col-sm-3 form-label"
            >Context</label
          >
          <div class="col-sm-9">
            <input
              id="entitySeparator"
              type="text"
              formControlName="sortingContext"
              class="form-control"
              placeholder=""
              aria-describedby="inputHelp"
            />
            <div id="entitySeparator" class="form-text">Document context.</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Cancel
  </button> -->
  <button
    class="btn btn-primary"
    *ngIf="isCreateMode"
    type="button"
    (click)="onCreateSubmit()"
  >
    Create
  </button>
  <button
    class="btn btn-primary"
    *ngIf="!isCreateMode"
    type="button"
    (click)="onEditSubmit()"
  >
    Update
  </button>
  <button
    class="btn btn-primary ml-4"
    *ngIf="!isCreateMode"
    type="button"
    (click)="onRemoveSubmit()"
  >
    Delete
  </button>
</div>
