import { WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable, single } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { AdminModule } from 'src/app/routes/admin/admin.module';

@Injectable({
  providedIn: AdminModule,
})
export class RouteListenerService {
  private _breadCrumb = Array<RouteLink>();

  public breadCrumb$ = signal(this._breadCrumb);

  projectId$ = signal<string>('');
  documentGroupId$ = signal<string>('');
  documentId$ = signal<string>('');

  _navigationEnd = this.router.events.subscribe((event) => {
    if (event instanceof ActivationEnd) {
      // Do something when the route changes
      //this._breadCrumb.push(routeLink)
      this.checkParams(event.snapshot.params, 'projectId', this.projectId$);
      this.checkParams(
        event.snapshot.params,
        'documentGroupId',
        this.documentGroupId$
      );
      this.checkParams(event.snapshot.params, 'documentId', this.documentId$);
      if (event.snapshot.data['isRoot']) return;
      let breadCrumb = this.buildBreadCrumb(event.snapshot);
      this._breadCrumb = breadCrumb as any;
    }
    if (event instanceof NavigationEnd) {
      // Do something when the route changes
      this.breadCrumb$.set(this._breadCrumb.reverse());
    }
  });

  constructor(private router: Router) {}

  buildBreadCrumb(snapshot: ActivatedRouteSnapshot) {
    let routeBuilder: string[] = [];
    let parts = snapshot.routeConfig?.path!.split('/').map((part) => {
      let title = '';
      let isDynamic = false;

      if (part == ':projectId') {
        title = 'projectName';
        isDynamic = true;
        routeBuilder.push(snapshot.params['projectId']);
      } else if (part == ':documentGroupId') {
        title = 'documentGroupName';
        isDynamic = true;
        routeBuilder.push(snapshot.params['documentGroupId']);
      } else if (part == ':documentId') {
        title = 'documentName';
        isDynamic = true;
        routeBuilder.push(snapshot.params['documentId']);
      } else {
        title = part;
        isDynamic = false;
        routeBuilder.push(part);
      }

      let partName: RouteLink = {
        title: title,
        route: routeBuilder.join('/'),
        isActive: false,
        isDynamic: isDynamic,
      };
      return partName;
    });
    return parts!.reverse();
  }
  checkParams(
    urlParams: any,
    paramName: string,
    paramSignal: WritableSignal<string>
  ) {
    if (urlParams[paramName] && paramSignal() != urlParams[paramName])
      paramSignal.set(urlParams[paramName]);
  }
}
interface RouteLink {
  title: string;
  route: string;
  isActive: boolean;
  isDynamic: boolean;
}
