import { AccountsService } from './../api/form-extract/api/accounts.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginAccountRequest, LoginAccountResponse } from '../api/form-extract';
import jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  get oauthModule(){
    return this.oauthService;
  }
  get isAuthenticated() {
    return this.oauthService.hasValidAccessToken();
  }
  get User(): User{
    return this.decodeUser()
  }
  get accessToken(){
    return sessionStorage.getItem('access_token') ?? null
  }


  constructor(
    private oauthService: OAuthService,
    private accountService: AccountsService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  login(request: LoginAccountRequest): Observable<LoginAccountResponse> {
    return this.accountService.loginAccount(request);
  }

  hasToken() {
    let user = this.decodeUser();
    if(user != null){
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

  }

  fromCallback(){
    let params = this.hashToDictionary();
    if(!params.access_token)
      return;
    this.saveToken(params)

  }
  saveToken(params: any){
    localStorage.setItem('access_token_json', JSON.stringify(params))
    window.sessionStorage.setItem('access_token', params.access_token);
  }
  removeToken(){
    localStorage.removeItem('access_token_json')
    window.sessionStorage.removeItem('access_token');
  }
  logout() {
    this.removeToken()
    this.router.navigate(['/auth'],{})
  }


  decodeUser(): User{
    const user:User = jwt_decode(this.accessToken!);
    return user;
  }

   hashToDictionary() {
    var hash = window.location.hash.substr(1); // Remove the leading "#"
    var hashPairs = hash.split('&'); // Split key-value pairs
    var hashDict = {} as any;

    for (var i = 0; i < hashPairs.length; i++) {
      var keyValue = hashPairs[i].split('=');
      var key = decodeURIComponent(keyValue[0]);
      var value = decodeURIComponent(keyValue[1]);
      hashDict[key] = value;
    }

    return hashDict;
  }
}
export interface User {
  aud: string
  iss: string
  iat: number
  nbf: number
  exp: number
  acr: string
  aio: string
  amr: string[]
  appid: string
  appidacr: string
  email: string
  family_name: string
  given_name: string
  ipaddr: string
  name: string
  oid: string
  onprem_sid: string
  rh: string
  scp: string
  sub: string
  tid: string
  unique_name: string
  upn: string
  uti: string
  ver: string
}

