import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn:'root'
})
export class PaginationService {

  pageOptions = [10,20,30,40,50]
  _pageNumber: number = 1;
  _pageSize: number | null = 10
  _pageCount: number | null = null;

  set pageSize(val: any) {
    this._pageSize = val;
    this.valueChanges$.next(this._pageNumber);
  }
  get pageSize(): number | null {
    return this._pageSize;
  }
  set pageNumber(val: any) {
    this._pageNumber = val;
    this.valueChanges$.next(val);
  }
  get pageNumber(): number | null {
    return this._pageNumber;
  }
  get pageCount(): Array<number> {
    return  Array.from({length: this._pageCount ?? 0}, (_, i) => i + 1)
  }
  set pageCount(val: any) {
    this._pageCount = val;
  }
  valueChanges$: Subject<number> = new Subject<number>();

  constructor() { }
}
