import { RouteListenerService } from './../route-listener.service';
import { Injectable, signal } from '@angular/core';
import { AuthService } from '../auth.service';
import { catchError, filter, from, map, observeOn, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Project, ProjectsService } from '../../api/form-extract';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { toObservable } from '@angular/core/rxjs-interop';
import { AdminProjectModalComponent } from 'src/app/routes/admin/modals/admin-project-modal/admin-project-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectHandlerService {
  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private modalService: NgbModal,
    private routeListener: RouteListenerService
  ) {}

  _projectId = toObservable(this.routeListener.projectId$)
    .pipe(
      filter((prm)=>prm != ''),
      tap((projectId) => {
        this.projectId = projectId;
        this.loadProjectRequest();
      })
    )
    .subscribe();

  projectId: string | null = null;
  projectList$ = signal<Array<Project>>([]);
  project$ = signal<Project | null>(null);

  loadProjectRequest() {
    let projects$ = this.projectService.getProject(this.projectId!);
    projects$
      .pipe(
        map((response) => response),
        tap((response) => this.project$.set(response.item!))
      )
      .subscribe();
  }

  loadProjectsRequest() {
    let projects$ = this.projectService.getProjects(undefined,undefined, '100', '1');
    projects$
      .pipe(
        map((response) => response),
        tap((response) => {
          this.projectList$.set(response.pagedItems!)
        })
      )
      .subscribe();
  }

  startProjectsRequest() {
    let projects$ = this.projectService.getProjects(undefined,undefined,'100', '1');
    projects$
      .pipe(
        map((response) => response),
        tap((response) => this.projectList$.set(response.pagedItems!))
      )
      .subscribe();
  }

  createProjectRequest() {
    const modalRef = this.modalService.open(AdminProjectModalComponent);
    modalRef.componentInstance.operation = 'Create';
    from(modalRef.result)
      .pipe(
        filter((val) => val.result),
        switchMap((val) =>
          this.projectService
            .createProject({
              name: val.value.name,
            })
            .pipe(
              tap((response) => {
                this.projectList$.mutate((current) =>
                  current.push(response.item!)
                );
              })
            )
        ),
        catchError((error) => error)
      )
      .subscribe();
  }
  editProjectRequest(project: Project) {
    const modalRef = this.modalService.open(AdminProjectModalComponent, {});
    modalRef.componentInstance.operation = 'Edit';
    modalRef.componentInstance.projectInfo = project;
    from(modalRef.result)
      .pipe(
        filter((val) => val.result),
        switchMap((val) =>
          this.projectService
            .patchProject(project.id!, {
              id: project.id!,
              name: val.value.name,
              description: val.value.description,
            })
            .pipe(
              tap((response) => {
                this.projectList$.mutate((current) => {
                  const ref = current.find((c) => c.id == project.id!)!;
                  ref.name = response.item?.name!;
                  ref.description = response.item?.description!;
                });
              })
            )
        ),
        catchError((error) => error)
      )
      .subscribe();
  }
  removeProjectRequest(project: Project) {
    const modalRef = this.modalService.open(AdminProjectModalComponent);
    modalRef.componentInstance.operation = 'Delete';
    modalRef.componentInstance.projectInfo = project;

    from(modalRef.result)
      .pipe(
        filter((val) => val.result),
        switchMap(() =>
          this.projectService.removeProject(project.id!).pipe(
            tap(() => {
              this.projectList$.mutate((current) => {
                const projectIndex = current.findIndex(
                  (c) => c.id == project.id!
                )!;
                current.splice(projectIndex, 1);
              });
            })
          )
        ),
        catchError((error) => error)
      )
      .subscribe();
  }
  uploadDocumentsRequest(project: Project) {
    this.router.navigate([project.id, 'upload']);
  }
}
