<div [formGroup]="form">
  <span class="d-inline-block mx-2">Search by name: </span>
  <input type="text" class="form-control" formControlName="name" />

  <span class="d-inline-block mx-2" *ngIf="documentView">View by: </span>
  <select formControlName="view" class="d-inline-block w-auto form-select" *ngIf="documentView">
    <option *ngFor="let mode of sfs.viewModes"  [value]="mode.value">{{mode.name}}</option>

  </select>

  <span class="d-inline-block mx-2" >Sort by: </span>
  <select formControlName="sort" class="d-inline-block w-auto form-select" >
    <option *ngFor="let mode of sfs.sortModes" [value]="mode.value">{{mode.name}}</option>

  </select>
  <span class="d-inline-block mx-2" *ngIf="documentView">Filter by: </span>
  <select formControlName="status" class="d-inline-block w-auto form-select" *ngIf="documentView">
    <option *ngFor="let mode of sfs.statusModes" [ngValue]="mode.value">{{mode.name}}</option>

  </select>
  <span class="d-inline-block mx-2">Page Size: </span>
  <select formControlName="pageSize" class="d-inline-block w-auto form-select">
    <option *ngFor="let mode of pgs.pageOptions" [value]="mode">{{mode}}</option>

  </select>
</div>
<!-- <button type="button" class="btn btn-light ms-1">Filter</button>
<button type="button" class="btn btn-light btn-icon ms-1">
    <i class="demo-pli-gear fs-5"></i>
</button> -->
