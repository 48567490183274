import { AuthService } from './auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router,private authService:AuthService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    activaRoute: ActivatedRoute,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

      if (!this.authService.isAuthenticated) {
        return this.router.parseUrl('/auth');
      } else {
        return true;
      }
  }
}
