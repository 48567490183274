import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { ApiModule, Configuration } from './shared/api/form-extract';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppConfigService } from './shared/services/app-config.service';
import { RouteListenerService } from './shared/services/route-listener.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminModule } from './routes/admin/admin.module';

const appInitializerFn = (appConfig: AppConfigService) => {
  return (): Promise<any> => {
    return appConfig.loadAppConfig();
  };
};
const apiServiceConfig = function (
  authService: OAuthService,
  appConfig: AppConfigService
) {
  return new Configuration({
    basePath: appConfig.configuration.apiUrl,
    accessToken: authService.getAccessToken.bind(authService),
  });
};

@NgModule({
  declarations: [AppComponent, AuthLayoutComponent, AdminLayoutComponent],
  imports: [
    OAuthModule.forRoot(),
    ApiModule,
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    AdminModule
  ],
  providers: [
    AppConfigService,
    RouteListenerService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: Configuration,
      useFactory: apiServiceConfig,
      deps: [OAuthService, AppConfigService],
      multi: false,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
