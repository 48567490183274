import { Component, Signal, signal } from '@angular/core';
import { mapToCanActivate } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';
import {
  DocumentGroup,
  GetDocumentGroupsResponse,
} from 'src/app/shared/api/form-extract';
import { GetDocumentGroupRequest } from 'src/app/shared/api/form-extract/model/getDocumentGroupRequest';
import { DocumentGroupHandlerService } from 'src/app/shared/services/mediator/documentGroupHandler.service';
import { PaginationService } from 'src/app/shared/services/pagination.service';

@Component({
  selector: 'app-admin-document-groups',
  templateUrl: './admin-document-groups.component.html',
  styleUrls: ['./admin-document-groups.component.scss'],
  providers: [PaginationService],
})
export class AdminDocumentGroupsComponent {
  //documentGroupList = signal(new Array());

  constructor(
    public handler: DocumentGroupHandlerService,
    public pgs: PaginationService
  ) {}
  ngOnInit(): void {
    this.pgs.valueChanges$
      .pipe(
        switchMap((pN) => this.paginate(pN)),
        tap((response) => {
          //this.documentGroupList.set(response.pagedItems!);
          this.pgs.pageCount = response.pageCount;
        })
      )
      .subscribe();
    this.pgs.pageNumber = 1;
  }

  start() {}
  createDocumentGroup() {
    this.handler.createDocumentGroupRequest();
  }
  editDocumentGroup(documentGroup: DocumentGroup) {
    this.handler.editDocumentGroupRequest(documentGroup);
  }
  removeDocumentGroup(documentGroup: DocumentGroup) {
    this.handler.removeDocumentGroupRequest(documentGroup);
  }
  paginate(pageNumber: number): Observable<GetDocumentGroupsResponse> {
    return this.handler.getDocumentGroupsRequest(
      this.pgs.pageSize!,
      pageNumber
    );
  }
}
