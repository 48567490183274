import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationService } from '../../services/pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {


  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

    public _pageCount: Array<number | null> = []

    @Input() pgs:PaginationService | null = null;
    // @Input()
    // set pageCount(val: number | null) {
    //   this._pageCount = Array.from({length: val!}, (_, i) => i + 1)
    // }
    // @Input() pageNumber: number | null = null
    // @Output() pageNumberChange: EventEmitter<number | null> = new EventEmitter<number | null>();

    paginate($event:any){
      this.pgs!.pageNumber = $event
    }

}
