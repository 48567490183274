import { Component, computed, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, tap } from 'rxjs';
import { Document, Page } from 'src/app/shared/api/form-extract';
import { DocumentGroupHandlerService } from 'src/app/shared/services/mediator/documentGroupHandler.service';
import { DocumentHandlerService } from 'src/app/shared/services/mediator/documentHandler.service';
import { environment } from 'src/environments/environment';
import { DecimalPipe  } from '@angular/common';

@Component({
  selector: 'app-page-document-pages-list',
  templateUrl: './page-document-pages-list.component.html',
  styleUrls: ['./page-document-pages-list.component.scss'],
})
export class PageDocumentPagesListComponent {
  _pages$ = signal<Page[] | null>(null);
  // documentPages$ = toObservable(this._document$)
  // .pipe(
  //   filter((doc)=>doc != null),
  //   map((doc)=> {
  //     let pages: any[] = []
  //     for (let index = 1; index <= doc?.totalPages!; index++) {
  //       let pageIndexNumber = index.toString().padStart(4,'0')
  //       pages.push({
  //         pageUrl: `${environment.blobStorage}/${this._document$()!.pagesStoragePath}/Page_${pageIndexNumber}.jpg`,
  //         downloadUrl:`${environment.blobStorage}/${this._document$()!.pagesStoragePath?.replace('pages','transformed')}/Page_${pageIndexNumber}.txt`,
  //       })
  //     }
  //     return pages;
  //   }))

  get blobStorage() {
    return environment.blobStorage;
  }

  constructor(
    private documentHandler: DocumentHandlerService,
    private groupHandler: DocumentGroupHandlerService
  ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.documentHandler
      .getDocumentPages(this.documentHandler.documentId!)
      .pipe(tap((response) =>
        this._pages$.set(response.items!)
      ))
      .subscribe();
  }
}
