
      <!-- CONTENTS -->
      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
      <section id="content" class="content">
          <div class="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
              <div class="content__wrap">
                <router-outlet></router-outlet>
              </div>
          </div>
      </section>

      <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
      <!-- END - CONTENTS -->
