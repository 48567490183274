import { Component, WritableSignal, computed } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import {
  Document,
  DocumentGroup,
  UpdateDocumentResponse,
} from 'src/app/shared/api/form-extract';
import { DocumentGroupHandlerService } from 'src/app/shared/services/mediator/documentGroupHandler.service';
import { DocumentHandlerService } from 'src/app/shared/services/mediator/documentHandler.service';
import { PaginationService } from 'src/app/shared/services/pagination.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-documents-queue',
  templateUrl: './page-documents-queue.component.html',
  styleUrls: ['./page-documents-queue.component.scss'],
  providers: [PaginationService]
})
export class PageDocumentsQueueComponent {
  get blobStorage() {
    return environment.blobStorage;
  }

  pageSize = '10';
  pageNumber = '1';
  documentList$: Observable<Array<Document>> = new Observable<
    Array<Document>
  >();

  get groupList() {
    return this.groupHandler.documentGroupList$;
  }

  constructor(
    private documentHandler: DocumentHandlerService,
    private groupHandler: DocumentGroupHandlerService,
    public pgs: PaginationService
  ) {}

  ngOnInit(): void {

    this.groupHandler.loadDocumentGroupsRequest();

    this.pgs.valueChanges$.subscribe((pageNumber) => {
      this.paginate(pageNumber);
    });
    this.pgs.pageNumber = 1;
  }

  paginate(pageNumber: number) {
    this.documentList$ = this.documentHandler
      .loadDocumentsRequest(
        undefined,
        ['Unsorted'],
        this.pgs.pageSize?.toString(),
        pageNumber?.toString()
      )
      .pipe(
        map((response) => {
          this.pgs.pageCount = response.pageCount;
          return response.pagedItems!;
        })
      );
  }

  sortDocument(item: Document, group: DocumentGroup) {
    let sortedDocument$ = this.documentHandler.sortDocument(
      item.id!,
      group.id!
    );
    sortedDocument$.subscribe(() => {
      this.paginate(this.pgs.pageNumber!)
    });
  }
  deleteItem(item: Document) {
    this.documentHandler.deleteDocument(item).subscribe((response) => {
      item.documentStatus = 'Extracting';
      this.paginate(parseInt(this.pageNumber))
    });
  }
}
