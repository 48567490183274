import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  configuration = {
    apiUrl: '',
  };
  loadAppConfig(): Promise<any> {
    return new Promise((resolve, reject) => {

      this.configuration.apiUrl = environment.apiUrl;
      resolve(true);
    });
  }

  constructor() {}
}
