<div class="modal-header" [ngSwitch]="operation">
  <h4 class="modal-title" *ngSwitchCase="'Create'">Create Project</h4>
  <h4 class="modal-title" *ngSwitchCase="'Edit'">Edit Project</h4>
  <h4 class="modal-title" *ngSwitchCase="'Delete'">Delete Project</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body" [ngSwitch]="operation">
    <!-- Custom styles -->
  <form class="row g-3 needs-validation" *ngSwitchCase="'Create'" novalidate="" [formGroup]="createForm">
    <div class="col-md-4">
      <label for="_dm-vCustomFirsname" class="form-label">Name</label>
      <input
        type="text"
        class="form-control"
        formControlName="name"
      />
      <div class="invalid-feedback">Please input your first name</div>
    </div>
    <div class="col-md">
      <label for="_dm-vCustomLastname" class="form-label">Description</label>
      <input
        type="text"
        class="form-control"
        formControlName="description"
      />
      <div class="valid-feedback">Looks good!</div>
      <div class="invalid-feedback">Please input your last name</div>
    </div>
  </form>
  <form class="row g-3 needs-validation" *ngSwitchCase="'Edit'" novalidate="" [formGroup]="editForm">
    <div class="col-md-4">
      <label for="_dm-vCustomFirsname" class="form-label">Name</label>
      <input
        type="text"
        class="form-control"
        formControlName="name"
      />
      <div class="invalid-feedback">Please input your first name</div>
    </div>
    <div class="col-md">
      <label for="_dm-vCustomLastname" class="form-label">Description</label>
      <input
        type="text"
        class="form-control"
        formControlName="description"
      />
      <div class="valid-feedback">Looks good!</div>
      <div class="invalid-feedback">Please input your last name</div>
    </div>
  </form>
  <div class="col text-center" *ngSwitchCase="'Delete'">
    <p>Confirm delete Project {{projectInfo?.name}}</p>
  </div>
  <!-- END : Custom styles -->
</div>
<div class="modal-footer" [ngSwitch]="operation">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Cancel
  </button>
  <button class="btn btn-primary" *ngSwitchCase="'Create'" type="button" (click)="onCreateSubmit()">Create</button>
  <button class="btn btn-primary" *ngSwitchCase="'Edit'" type="button" (click)="onEditSubmit()">Update</button>
  <button class="btn btn-primary" *ngSwitchCase="'Delete'" type="button" (click)="onRemoveSubmit()">Delete</button>
</div>
