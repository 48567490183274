<div
  class="flex-grow-1 d-flex flex-wrap justify-content-end align-items-center gap-3"
>
  <!-- <button
    type="button"
    class="btn btn-secondary btn-lg hstack gap-2 mb-1"
    [routerLink]="'../queue'"
  >
    <i class="demo-psi-add fs-4"></i>
    <span class="vr"></span>
    Add new
  </button> -->

  <div class="w-auto text-nowrap mb-1">
    <span class="d-inline-block me-2">Sort by : </span>
    <select class="d-inline-block w-auto form-select">
      <option value="date-created" selected="">Date Created</option>
      <option value="date-modified">Date Modified</option>
      <option value="frequency-used">Frequency Used</option>
      <option value="alpabetically">Alpabetically</option>
      <option value="alpabetically-reversed">Alpabetically Reversed</option>
    </select>

    <button type="button" class="btn btn-light ms-1">Filter</button>
    <button type="button" class="btn btn-light btn-icon ms-1">
      <i class="demo-pli-gear fs-5"></i>
    </button>
  </div>
</div>
<div class="card mb-4">
  <div class="card-body">
    <div class="row">
      <div
        class="col-sm-6 col-md-4 col-xl-3 mb-3"
        *ngFor="let item of documentList$ | async"
      >
        <app-document-preview [item]="item">

            <ul class="dropdown-menu dropdown-menu-end">
              <li
                (click)="sortDocument(item, group)"
                *ngFor="let group of groupList()"
              >
                <button class="dropdown-item">
                  <i class="demo-pli-pen-5 fs-5 me-2"></i> {{ group.name }}
                </button>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <button (click)="deleteItem(item)" class="dropdown-item">
                  <i class="demo-pli-remove fs-5 me-2"></i> Delete
                </button>
              </li>
            </ul>

        </app-document-preview>
      </div>
      <app-pagination [pgs]="pgs"></app-pagination>
    </div>
  </div>
</div>
