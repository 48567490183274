import { DocumentsService } from './../api/form-extract/api/documents.service';
import { HttpContext, HttpContextToken } from '@angular/common/http';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  constructor(private documentService: DocumentsService) {}

  upload(
    file: File,
    projectId: string,
    documentGroupId: string | undefined
  ): Observable<HttpEvent<any>> {
    let files = [file] as Blob[];
    return this.documentService.uploadDocuments(
      projectId,
      documentGroupId,
      files,
      'events',
      true
    );
    //return uploadRequest$ as any;
  }

  getFiles(): Observable<any> {
    return of([]);
    //return this.http.get(`${this.baseUrl}/files`);
  }
}
