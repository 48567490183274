import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { NgbAlertModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { AdminDocumentModalComponent } from './modals/admin-document-modal/admin-document-modal.component';
import { AdminDocumentGroupsComponent } from './pages/admin-document-groups/admin-document-groups.component';
import { AdminUploadComponent } from './pages/admin-upload/admin-upload.component';
import { PageDocumentsQueueComponent } from './pages/page-documents-queue/page-documents-queue.component';
import { PageDocumentListComponent } from './pages/page-document-list/page-document-list.component';
import { PageDocumentPagesListComponent } from './pages/page-document-pages-list/page-document-pages-list.component';
import { DocumentPreviewComponent } from './controls/document-preview/document-preview.component';
import { PaginationComponent } from 'src/app/shared/components/pagination/pagination.component';
import { SortFilterComponent } from 'src/app/shared/components/sort-filter/sort-filter.component';
import { DocumentViewerModalComponent } from './modals/document-viewer-modal/document-viewer-modal.component';
import { FormStudioComponent } from './pages/form-studio/form-studio.component';
import { FormFieldComponent } from './pages/form-studio/form-field/form-field.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { AdminPageSettingsModalComponent } from './modals/admin-page-settings-modal/admin-page-settings-modal.component';


@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminDocumentModalComponent,
    AdminDocumentGroupsComponent,
    AdminUploadComponent,
    PageDocumentsQueueComponent,
    PageDocumentListComponent,
    PageDocumentPagesListComponent,
    DocumentPreviewComponent,
    PaginationComponent,
    SortFilterComponent,
    DocumentViewerModalComponent,
    FormStudioComponent,
    FormFieldComponent,
    AdminPageSettingsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    NgbModalModule,
    NgbAlertModule,
    DragAndDropModule
  ],
  providers:[

  ]

})
export class AdminModule { }
