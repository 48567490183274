<div class="flex-grow-1 d-flex flex-wrap justify-content-end align-items-center gap-3">

  <button type="button" class="btn btn-secondary btn-lg hstack gap-2 mb-1" (click)="createProject()">
      <i class="demo-psi-add fs-4"></i>
      <span class="vr"></span>
      Add new
  </button>

  <div class="w-auto text-nowrap mb-1">
     <app-sort-filter></app-sort-filter>
  </div>
</div>
<div class="card mb-4">
  <div class="card-body">
    <div class="row">

    </div>
  </div>
  <div class="card-body" >
 
    <div class="row">

      <div class="col-sm-6 col-md-4 col-xl-3 mb-3" *ngFor="let item of projectList()">
        <div class="card">
            <div class="card-body pt-2">

                <!-- Favorite button and option dropdown -->
                <div class="d-flex justify-content-end gap-1">
                    <button type="button" class="btn btn-sm btn-icon btn-hover btn-white shadow-none text-orange add-tooltip" data-bs-original-title="Remove from Favorites">
                        <i class="demo-psi-star fs-5"></i>
                    </button>
                    <div class="dropdown">
                        <button class="btn btn-sm btn-icon btn-hover btn-light shadow-none" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="demo-pli-dot-horizontal fs-4"></i>
                            <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" style="">
                            <li (click)="editProject(item)">
                                <a href="#" class="dropdown-item">
                                    <i class="demo-pli-pen-5 fs-5 me-2"></i> Edit
                                </a>
                            </li>
                            <li (click)="removeProject(item)">
                                <a href="#" class="dropdown-item text-danger">
                                    <i class="demo-pli-recycling fs-5 me-2"></i> Remove
                                </a>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li (click)="uploadDocuments(item)">
                              <button class="dropdown-item">
                                  <i class="demo-pli-recycling fs-5 me-2"></i> Uploa Documents
                              </button>
                          </li>
                            <!--li>
                                <a href="#" class="dropdown-item">
                                    <i class="demo-pli-mail fs-5 me-2"></i> Send a Message
                                </a>
                            </!--li>
                            <li>
                                <a href="#" class="dropdown-item">
                                    <i class="demo-pli-calendar-4 fs-5 me-2"></i> View Details
                                </a>
                            </li>
                            <li>
                                <a href="#" class="dropdown-item">
                                    <i class="demo-pli-lock-user fs-5 me-2"></i> Lock
                                </a>
                            </li-->
                        </ul>
                    </div>
                </div>
                <!-- END : Favorite button and option dropdown -->

                <!-- Profile picture and short information -->
                <div class="text-center position-relative" (click)="groups(item)">
                    <div class="pb-3">
                        <img class="img-lg" src="/assets/img/drawer.png" alt="Profile Picture" loading="lazy">
                    </div>
                    <a [routerLink]="[item.id,'groups']" (click)="groups(item)" class="h5 stretched-link btn-link">{{item.name}}</a>
                    <p class="text-muted">Last Update: 09/06/2023 09:23 AM</p>
                </div>

                <!-- END : Profile picture and short information -->

                <!-- Social media buttons -->
                <div class="mt-4 pt-3 d-flex justify-content-around border-top">
                  <div class="text-center">
                      <h4 class="mb-1">0</h4>
                      <small class="text-muted">Folders</small>
                  </div>
                  <div class="text-center">
                      <h4 class="mb-1">0</h4>
                      <small class="text-muted">Documents</small>
                  </div>
              </div>
                <!-- END : Social media buttons -->

            </div>
        </div>
    </div>
    </div>
  </div>
</div>
