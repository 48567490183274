import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SortFilterChangeEvent } from './SortFilterChangeEvent';

@Injectable({
  providedIn:'root'
})
export class SortingFilterService {
  vmPageView: string = 'pageView';
  vmTableView = 'tableView';
  srCreated: string = 'createdDate';
  srModified = 'modifiedDate';
  srStatus = 'status';
  srAlpha = 'alpha';
  srAlphaReverse = 'alphaReverse';

  stParsingInProgress='ParsingInProgress'
  stTransformInProgress='TransformInProgress'
  stTransformed='Transformed'

  viewModes = [
    { name: 'Page View', value: this.vmPageView },
    { name: 'Table View', value: this.vmTableView },
  ];
  sortModes = [
    { name: 'Created Date', value: this.srCreated },
    { name: 'Modified Date', value: this.srModified },
    { name: 'Status', value: this.srStatus },
    { name: 'Alphabetically', value: this.srAlpha },
    { name: 'Alphabetically Reverse', value: this.srAlphaReverse },
  ];
  statusModes = [
    { name: 'Any', value: null },
    { name: 'Parsing In Progress', value: [this.stParsingInProgress] },
    { name: 'Tranform In Progress', value: [this.stTransformInProgress] },
    { name: 'Transformed', value: [this.stTransformed] }
  ];

  get value() :SortFilterChangeEvent {
    return this.valueChanges$.value;
  }
  valueChanges$: BehaviorSubject<SortFilterChangeEvent> = new BehaviorSubject<SortFilterChangeEvent>({
    name : null,
    pageSize: null,
    sort: null,
    status: null
  });
  changed(evt:SortFilterChangeEvent) {
    this.valueChanges$.next(evt);
  }
  constructor() { }
}

