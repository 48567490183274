import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from './shared/services/auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./routes/admin/admin.module').then((m) => m.AdminModule),
    data: {
      isRoot: true,
      title: 'Home',
    },
  },
  {
    path: 'cli',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./routes/cli/cli.module').then((m) => m.CliModule),
    data: {
      isRoot: true,
      title: 'Home',
    },
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./routes/auth/auth.module').then((m) => m.AuthModule),
  },

  { path: '', redirectTo: '/admin', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
