import { Component } from "@angular/core";
import { RouteListenerService } from "./shared/services/route-listener.service";
import { AuthService } from "./shared/services/auth.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FormExtract.UI';

  constructor(private authService: AuthService) {

  }

  ngOnInit(): void {
    this.authService.fromCallback()

  }


}
