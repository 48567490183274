import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Document,
  DocumentsService,
  PagesService,
} from 'src/app/shared/api/form-extract';
import { environment } from 'src/environments/environment';
import { DocumentViewerModalComponent } from '../../modals/document-viewer-modal/document-viewer-modal.component';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  filter,
  map,
  switchMap,
  tap,
  timer,
} from 'rxjs';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnDestroy, OnInit {
  @Input() item: any | null = null;
  @Input() tableView: boolean = false;

  docStatusEvent$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  docStatusTimer$ = timer(0, 30000);
  docStatusTimer_: Subscription | null = null;

  public get blobPath(): string {
    return environment.blobStorage;
  }
  public get coverUrl(): string {
    return `${this.item?.documentStoragePath}\\thumbnails\\${
      this.item?.pageName || 'Page_0001.jpg'
    }`;
  }
  public eventStatus(doc: any): string {
    return doc?.documentStatusEvent || doc?.pageStatusEvent;
  }

  constructor(
    private modalService: NgbModal,
    private documentServices: DocumentsService,
    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    this.docStatusTimer_ = this.docStatusTimer$
      .pipe(
        tap((val) => console.log(val)),
        map((interval) => {
          return this.docStatusEvent$.getValue();
        }),
        filter((docStatus) => {
          return docStatus != 'Transformed';
        }),
        switchMap(() =>
          this.item.pageName
            ? this.pageService.getPage(
                this.item.id,
                this.item.projectId,
                this.item.documentId
              )
            : this.documentServices.getDocument(
                this.item.id,
                this.item.projectId
              )
        )
      )
      .subscribe((docResponse) => {
        const eventStatus = this.eventStatus(docResponse.item);
        this.docStatusEvent$.next(eventStatus ?? 'N/A');
      });
  }

  view(item: Document) {
    const modalRef = this.modalService.open(DocumentViewerModalComponent, {
      fullscreen: false,
    });
    modalRef.componentInstance.page = item;
  }
  reprocess(item: any) {}
  error_image($event: any) {
    $event.currentTarget.src = '/assets/img/time.png';
  }

  ngOnDestroy(): void {
    if (this.docStatusTimer_) this.docStatusTimer_.unsubscribe();
  }
}
