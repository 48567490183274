<nav class="text-align-center mt-5" aria-label="Table navigation" *ngIf="pgs">
  <ul class="pagination justify-content-center">
      <li class="page-item disabled">
          <a class="page-link" href="#">Previous </a>
      </li>
      <li *ngFor="let row of pgs.pageCount;" class="page-item" [ngClass]="{'active':pgs.pageNumber == row}" (click)="paginate(row)" aria-current="page">
        <span class="page-link">{{row}}</span>
      </li>
      <li class="page-item">
          <a class="page-link" href="#">Next</a>
      </li>
  </ul>
</nav>
