import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ControlConfig, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateDocumentGroupRequest, DocumentGroup, Project } from 'src/app/shared/api/form-extract';

@Component({
  selector: 'app-admin-document-group-modal',
  standalone: true,
  templateUrl: './admin-document-group-modal.component.html',
  styleUrls: ['./admin-document-group-modal.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class AdminDocumentGroupModalComponent {


  editForm = this.fb.group({
    name: new FormControl<string | null | undefined>(null, [Validators.required]),
    description: new FormControl<string | null | undefined>(null),
    instructions: new FormControl<string | null | undefined>(null),
    preProcessingScript: new FormControl<string | null | undefined>(null),
    posProcessingScript: new FormControl<string | null | undefined>(null),
    isSingleEntityDocument: new FormControl<boolean | null | undefined>(false),
    isMultipleEntityDocument: new FormControl<boolean | null | undefined>(false),
    isPdfExtractSupported: new FormControl<boolean | null | undefined>(false),
    isGenerativeAiEnabled: new FormControl<boolean | null | undefined>(true),
    entitySeparator: new FormControl<string | null | undefined>(null),
    entityStart: new FormControl<string | null | undefined>(null),
    sortingKeywords: new FormControl<string | null | undefined>(null),
    sortingContext: new FormControl<string | null | undefined>(null),
    llmTemperature: new FormControl<string | null | undefined>(null),
    llmSystemPrompt: new FormControl<string | null | undefined>(null),
    ocrLanguage: new FormControl<string | null | undefined>(null),
    ocrCustomInput: new FormControl<string | null | undefined>(null),
    isVisionApiEnabled: new FormControl<boolean | null | undefined>(null),
    visionApiPrompt: new FormControl<string | null | undefined>(null),
    ocrPageMode: new FormControl<string | null | undefined>(null),
    isLayoutModeEnabled: new FormControl<boolean | null | undefined>(null),
    pdfReaderStartPageNumber: new FormControl<string | null | undefined>(null),
    pdfReaderEndPageNumber: new FormControl<string | null | undefined>(null),
    pdfReaderTextLayout: new FormControl<string | null | undefined>(null),
  })

  public documentGroupInfo: DocumentGroup | null =  null;
  isCreateMode = false;

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) {

  }
  ngOnInit(): void {
    if(this.documentGroupInfo == null){
      this.isCreateMode = this.documentGroupInfo == null;
    } else {
      this.editForm.setValue({
        name: this.documentGroupInfo.name,
        instructions: this.documentGroupInfo.instructions,
        description: this.documentGroupInfo.description,
        preProcessingScript: this.documentGroupInfo.preProcessingScript,
        posProcessingScript: this.documentGroupInfo.posProcessingScript,
        isSingleEntityDocument: this.documentGroupInfo.isSingleEntityDocument,
        isMultipleEntityDocument: this.documentGroupInfo.isMultipleEntityDocument,
        isPdfExtractSupported: this.documentGroupInfo.isPdfExtractSupported,
        isGenerativeAiEnabled: this.documentGroupInfo.isGenerativeAiEnabled,
        entitySeparator: this.documentGroupInfo.entitySeparator,
        entityStart: this.documentGroupInfo.entityStart,
        sortingKeywords: this.documentGroupInfo.sortingKeywords,
        sortingContext: this.documentGroupInfo.sortingContext,
        llmTemperature: this.documentGroupInfo.llmTemperature,
        llmSystemPrompt: this.documentGroupInfo.llmSystemPrompt,
        ocrLanguage: this.documentGroupInfo.ocrLanguage,
        ocrCustomInput: this.documentGroupInfo.ocrCustomInput,
        isVisionApiEnabled: this.documentGroupInfo.isVisionApiEnabled,
        visionApiPrompt: this.documentGroupInfo.visionApiPrompt,
        ocrPageMode: this.documentGroupInfo.ocrPageMode,
        isLayoutModeEnabled: this.documentGroupInfo.isLayoutModeEnabled,
        pdfReaderStartPageNumber: this.documentGroupInfo.pdfReaderStartPageNumber,
        pdfReaderEndPageNumber: this.documentGroupInfo.pdfReaderEndPageNumber,
        pdfReaderTextLayout: this.documentGroupInfo.pdfReaderTextLayout

      })
    }
  }

  onCreateSubmit() {
    // TODO: Use EventEmitter with form value
    if(this.editForm.valid)
      this.activeModal.close({
        result: true,
        value: this.editForm.value,
      });
  }
  onEditSubmit() {
    // TODO: Use EventEmitter with form value
    if(this.editForm.valid)
      this.activeModal.close({
        result: true,
        value: this.editForm.value,
      });
  }
  onRemoveSubmit() {
    // TODO: Use EventEmitter with form value

      this.activeModal.close({
        result: true,
        value: this.editForm.value,
      });
  }
}
