import { Event } from './../../../../../../node_modules/undici-types/patch.d';
import { Component, OnInit, signal } from '@angular/core';
import { forkJoin, switchMap, filter, tap } from 'rxjs';
import {
  Document,
  GetPagesResponse,
  Page,
  PagesService,
} from 'src/app/shared/api/form-extract';
import { DocumentHandlerService } from 'src/app/shared/services/mediator/documentHandler.service';
import { IFormField } from './form-field/form-field.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-studio',
  templateUrl: './form-studio.component.html',
  styleUrls: ['./form-studio.component.scss'],
})
export class FormStudioComponent implements OnInit {
  constructor(
    private documentHandler: DocumentHandlerService,
    private pageService: PagesService
  ) {}

  document$ = signal<GetPagesResponse | null>(null);
  page$ = signal<Page | null>(null);
  output$ = signal<any | null>(null);
  selectedField: IFormField | null = null;
  zoom = 1;
  ngOnInit(): void {
    this.documentHandler
      .getDocumentPages(this.documentHandler.documentId!)
      .pipe(
        switchMap((response) => {
          return forkJoin(
            response.items!.map((page) =>
              this.pageService.getPage(
                page.id!,
                this.documentHandler.document$()?.projectId!,
                this.documentHandler.documentId!
              )
            )
          );
        })
      )
      .subscribe((pages) => {
        this.page$.set(pages[0].item!);
        var document = this.documentHandler.document$();
        this.setActivePage(document!, pages[0].item!);
      });
    //this.doOCR()
  }
  zoomChange(event: any) {
    if (event.wheelDeltaY > 0) {
      if (this.zoom > 2) {
        this.zoom = 2;
      } else {
        this.zoom = this.zoom + 0.1;
      }
    } else {
      if (this.zoom <= 0.5) {
        this.zoom = 0.5;
      } else {
        this.zoom = this.zoom - 0.1;
      }
    }
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
  setActivePage(document: Document, page: Page) {
    this.layout = {
      pageCoverUrl: `${environment.blobStorage}/${page.storagePath}`,
      width: page.width!,
      height: page.height!,
      fields: page.rectangles
        ?.filter((entry) => entry.top! + entry.left! + entry.x! + entry.y! != 0)
        .map((entry) => {
          let field = {
            name: '',
            type: 'number',
            valueNumber: 0,
            text: '',
            top: entry.top,
            left: entry.left,
            width: entry.width,
            height: entry.height,
            page: 1,
            confidence: 0.001,
            elements: [],
            active: false
          };
          return field;
        }) as any,
    };
  }
  updateField(field: IFormField) {
    this.selectedField = field;
    var layoutForm = this.buildOutput(this.layout)
    this.output$.set(layoutForm);
    var doc = this.documentHandler.document$();
    var page = this.page$();
    this.pageService
      .createPageForm(doc!.projectId!, doc!.id!, page!.id!,{
        documentId: doc!.id,
        pageId: page!.id,
        projectId: doc!.projectId,
        form: JSON.stringify(layoutForm)
      })
      .subscribe();
  }
  updateBackField(event: any) {
    if(this.selectedField?.name != '')
      this.selectedField!.active = true
    //this.selectedField = field
  }
  buildOutput(layout: any) {
    let output = {
      documentName: 'SegundaVia_2023.pdf',
      pageNumber: 1,
      fields: layout.fields
        .filter((f: any) => f.name != '')
        .map((fld: any) => {
          let field = {
            name: fld.name,
            type: fld.type,
            valueNumber: 0,
            text: '',
            top: fld.top,
            left: fld.left,
            width: fld.width,
            height: fld.height
          };
          return field;
        }),
    };
    return output;
  }

  layout = {
    pageCoverUrl: '',
    width: 0,
    height: 0,
    fields: [],
  };
}
