<div class="card" *ngIf="item && tableView == false">
  <div class="card-body pt-2">
    <!-- Favorite button and option dropdown -->
    <div class="d-flex justify-content-end gap-1">
      <div class="dropdown">
        <button
          class="btn btn-sm btn-icon btn-hover btn-light shadow-none"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="demo-pli-dot-horizontal fs-4"></i>
          <span class="visually-hidden">Toggle Dropdown</span>
        </button>
        <ng-content select="ul"></ng-content>
      </div>
    </div>
    <!-- END : Favorite button and option dropdown -->

    <!-- Profile picture and short information -->
    <div
      class="text-center position-relative doc-thumbnail"
      (click)="view(item)"
    >
      <div class="pb-3 thumb-preview">
        <img
          class="img-thumbnail"
          [src]="blobPath + '/' + coverUrl"
          alt="COVER URL"
          loading="lazy"
          (error)="error_image($event)"
        />
        <div class="btn btn-secondary btn-xs my-1">
          {{ docStatusEvent$ | async }}
        </div>
      </div>

      <a [routerLink]="[item.id, 'pages']" class="h5 stretched-link btn-link">{{
        item.name
      }}</a>
      <p class="text-muted">
        Last Update: {{ item.modified | date : "short" }}
      </p>
    </div>
  </div>
</div>
<div class="row" *ngIf="tableView == true && item">
  <div class="flex-table-row">
    <div class="flex-table-cell">
      <a [routerLink]="[item.id, 'pages']" class="h5 btn-link">{{
        item.name
      }}</a>
    </div>
    <div class="flex-table-cell">{{ item.created | date : "short" }}</div>
    <div class="flex-table-cell">{{ item.modified | date : "short" }}</div>
    <div class="flex-table-cell">{{ docStatusEvent$ | async }}</div>
    <div class="flex-table-cell">
      <div class="dropdown">
        <button
          class="btn btn-sm btn-icon btn-hover btn-light shadow-none"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="demo-pli-dot-horizontal fs-4"></i>
          <span class="visually-hidden">Toggle Dropdown</span>
        </button>
        <ng-content select=".menu"></ng-content>
      </div>
    </div>
  </div>
</div>
