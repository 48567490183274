import { Component } from "@angular/core";


@Component({
  selector: 'div[app-auth-layout]',
  host: { // this is applied to 'my-node' in this case
    "[class]": "'root front-container'",
    "[attr.id]": "'root'",
 },
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent {

}
